<messages>["./RegistryData"]</messages>

<template>
  <simple-card :title="title">
    <div class="ma-3">
      <name-value
        v-for="item in properties"
        :key="item.name"
        :name="item.name"
        :value="item.value"/>
    </div>
    <simple-card :title="$t('label.eligibilityFields')">
      <eligibility-field
        v-for="field in eligibilityFields"
        :key="field.key"
        :value="field"/>
    </simple-card>
  </simple-card>
</template>

<script>
  import NameValue from '@/app/core/components/NameValue'
  import SimpleCard from '@/app/core/components/SimpleCard'
  import EligibilityField from './EligibilityField'

  import PropertyMapper from '@/app/core/mixins/PropertyMapper'

  export default {
    name: 'RegistryLaunchPhase',

    components: {
      EligibilityField,
      NameValue,
      SimpleCard
    },

    mixins: [PropertyMapper],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      title () {
        const lp = this.value
        return lp.subname ? `${lp.name} - ${lp.subname}` : lp.name
      },

      properties () {
        return ['claimsRequired', 'signedMarkRequired'].map (
          this.propMapper (this.value))
      },

      eligibilityFields () {
        return this.value?.eligibilityFields?.length
          ? this.value.eligibilityFields
          : []
      }
    }
  }
</script>
