<messages>["./RegistryData"]</messages>

<template>
  <simple-card :title="title">
    <div class="ma-3">
      <name-value
        v-for="item in properties"
        :key="item.name"
        :name="item.name"
        :value="item.value"/>
    </div>
    <simple-card
      :elevation="0"
      :title="$t('label.validationData')">
      <name-value
        v-for="item in validationData"
        :key="item.name"
        :name="item.name"
        :value="item.value"/>
    </simple-card>
  </simple-card>
</template>

<script>
  import NameValue from '@/app/core/components/NameValue'
  import SimpleCard from '@/app/core/components/SimpleCard'

  import PropertyMapper from '@/app/core/mixins/PropertyMapper'

  export default {
    name: 'EligibilityField',

    components: {
      NameValue,
      SimpleCard
    },

    mixins: [PropertyMapper],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      title () {
        return this.value?.name
      },

      properties () {
        // const keys = Object.keys (this.value)
        const keys = [
          'key',
          'description',
          'example',
          'multiValued'
        ]
        const properties = keys.map (it => ({
          name: it,
          value: `${this.value[it]}`
        }))

        return properties
      },

      validationData () {
        const properties = Object.keys (this.value.validationData).map (it => ({
          name: it,
          value: `${this.value.validationData[it]}`
        }))

        return properties
      }
    }
  }
</script>
