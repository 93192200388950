<template>
  <base-layout mw1>
    <v-col cols="12">
      <!-- "registry does not exist" alert -->
      <v-alert
        type="error"
        :value="!isLoading && !hasDetails">
        {{ $t (`general.invalid.registry`, {registry: id}) }}
      </v-alert>

      <!-- registry details -->
      <v-card v-if="hasDetails">
        <v-card-title primary-title>
          <div>
            <div class="text-h5" v-text="registryName"/>

            <div class="cgwng-subheading" v-text="id"/>
          </div>
        </v-card-title>
        <v-card-text>
          <registry-data :value="value"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import RegistryData from './components/RegistryData'

  export default {
    name: 'RegistryView',

    components: {
      BaseLayout,
      RegistryData
    },

    props: {
      id: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        isLoading: true,
        registry: null,
        registryTypeData: null
      }
    },

    computed: {
      hasDetails () {
        return !!this.registry
      },

      registryName () {
        return this.registryTypeData?.label
      },

      value () {
        return {
          // ...this.registryTypeData,
          ...this.registry
        }
      }
    },

    watch: {
      id () {
        this.loadRegistryData ()
      }
    },

    created () {
      this.loadRegistryData ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData',
        getRegistryTypeData: 'cache/getRegistryTypeData'
      }),

      loadRegistryData () {
        this.registryTypeData = null
        this.registry = null

        this.getRegistryTypeData (this.id).then (data => {
          this.registryTypeData = data?.[this.id]
        })

        this.isLoading = true

        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {registryId: this.id},
          cb: data => {
            if (data.metaDataSupported) {
              this.registry = data.registryMetaData
            }
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
