<messages>["./RegistryData"]</messages>

<template>
  <v-row>
    <v-col
      v-for="section in sections.filter (s => s.i && s.i.length)"
      :key="section.l"
      class="d-flex"
      cols="12" md="6">
      <simple-card
        :title="$t(`section.${section.l}`)"
        content-class="pa-6">
        <name-value-block :items="section.i"/>
      </simple-card>
    </v-col>

    <!-- support commands -->
    <v-col
      v-if="supportedCommands.length"
      class="d-flex"
      cols="12" md="6">
      <simple-card
        :title="$t('section.commands')"
        content-class="pa-4">
        <registry-commands :value="supportedCommands"/>
      </simple-card>
    </v-col>

    <!-- grace periods -->
    <v-col
      v-if="supportedCommands.length"
      class="d-flex"
      cols="12" md="6">
      <simple-card
        :title="$t('section.gracePeriods')"
        content-class="pa-6">
        <grace-periods :value="gracePeriods"/>
      </simple-card>
    </v-col>

    <!-- launch phases -->
    <v-col
      v-if="launchPhases.length"
      class="d-flex"
      cols="12">
      <simple-card :title="$t('section.launchPhases')">
        <v-row>
          <v-col
            v-for="launchPhase in launchPhases"
            :key="`${launchPhase.name}-${launchPhase.subName}`"
            class="justify-space-between pa-4"
            cols="12" md="6">
            <registry-launch-phase :value="launchPhase"/>
          </v-col>
        </v-row>
      </simple-card>
    </v-col>
  </v-row>
</template>

<script>
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import SimpleCard from '@/app/core/components/SimpleCard'
  import RegistryCommands from './RegistryCommands'
  import GracePeriods from './GracePeriods'
  import RegistryLaunchPhase from './RegistryLaunchPhase'

  import PropertyMapper from '@/app/core/mixins/PropertyMapper'

  export default {
    name: 'RegistryData',

    components: {
      GracePeriods,
      NameValueBlock,
      RegistryCommands,
      RegistryLaunchPhase,
      SimpleCard
    },

    mixins: [PropertyMapper],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      sections () {
        return [
          {l: 'basic', i: this.pairs}
        ]
      },

      pairs () {
        const special = [
          'baseNames', 'gracePeriods', 'launchPhases', 'supportedCommands']

        const baseNames = this.value?.baseNames.map (it => '.' + it).join (' ')

        const pairs = this.value
          ? Object.keys (this.value).filter (it => !special.includes (it)).map (
            this.propMapper (this.value))
          : []

        return [
          ...(baseNames
            ? [{
              name: this.$t ('label.baseNames'),
              value: baseNames
            }]
            : []),
          ...pairs
        ]
      },

      supportedCommands () {
        return this.value?.supportedCommands || []
      },

      gracePeriods () {
        return this.value?.gracePeriods || {}
      },

      launchPhases () {
        return this.value?.launchPhases?.length
          ? this.value.launchPhases
          : []
      }
    }
  }
</script>
