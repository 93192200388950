<template>
  <table>
    <thead>
      <tr>
        <th/>
        <th
          v-for="ob in registryObjects"
          :key="ob"
          class="px-2"
          v-text="ob"/>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="op in objectOperations"
        :key="op"
        class="nameValue">
        <th class="pa-2" v-text="op"/>
        <td
          v-for="ob in registryObjects"
          :key="`${ob}${op}`"
          class="pa-1">
          <v-icon
            v-if="mark (ob, op)"
            color="green darken-2">
            check_circle
          </v-icon>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  const SPLIT_REGEXP = /^(.[^A-Z]+)(.*)/

  export default {
    name: 'RegistryCommands',

    props: {
      value: {
        type: Array,
        required: true
      }
    },

    computed: {
      registryObjects () {
        const objects = new Set ()

        this.value.forEach (it => {
          const o = (it.match (SPLIT_REGEXP))[1]

          if (o) {
            objects.add (o)
          }
        })

        return Array.from (objects)
      },

      objectOperations () {
        const operations = new Set ()

        this.value.forEach (it => {
          const o = (it.match (SPLIT_REGEXP))[2]

          if (o) {
            operations.add (o)
          }
        })

        return Array.from (operations)
      }
    },

    methods: {
      mark (ob, op) {
        return this.value?.includes?. (ob + op)
      }
    }
  }
</script>

<style scoped>
table {
  width: 100%;
}

tbody th {
  text-align: left;
}

td {
  text-align: center;
}
</style>
