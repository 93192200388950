<messages>["./GracePeriods"]</messages>

<template>
  <name-value-block :items="items"/>
</template>

<script>
  import DatetimeHelper from '@/app/core/mixins/DatetimeHelper'
  import NameValueBlock from '@/app/core/components/NameValueBlock'

  export default {
    name: 'GracePeriods',

    components: {NameValueBlock},

    mixins: [DatetimeHelper],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      items () {
        return Object.keys (this.value).map (it => ({
          name: this.$t (it),
          value: this.formatDuration (this.value[it])
        }))
      }
    }
  }
</script>
